.here-container {
    /* @apply w-full; */
    height: 100vh;
}

.H_ctl {
    margin: 5px;
}

.H_icon_ft {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
}

.H_btn {
    width: 32px;
    height: 32px;
    box-shadow: 0px 0px 4px 1px rgba(107, 117, 124, 0.32);
    color: #26333b;
    border-radius: 4px;
}

.H_btn:hover {
    color: #316ee8;
}

.H_btn.H_active {
    background: #fff;
    color: #316ee8;
}

.cursor {
    cursor: crosshair !important;
}

.H_active>svg.H_icon,
.H_active:hover>svg.H_icon {
    fill: none !important;
}

.H_l_vertical .H_zoom .H_el::after {
    width: 2rem;
    left: 0;
}

.H_ctl.H_grp {
    box-shadow: 0px 0px 4px 1px rgba(107, 117, 124, 0.32);
}

.buttonCluster {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -7.8rem;
}

.H_ctl:nth-child(1) {
    margin-top: 3rem;
}

.H_ctl:nth-child(3) {
    position: absolute;
    top: 10.2rem;
}

.H_ctl:nth-child(3) .H_btn {
    border-radius: 0 0 4px 4px;
}

.H_ctl:nth-child(4) {
    margin-top: 5.8rem;
}

.H_ctl:nth-child(4) .H_btn {
    border-radius: 4px 4px 0 0;
}

.H_ctl:nth-child(5) {
    position: absolute;
    top: 15.52rem;
}

.H_ctl:nth-child(5) .H_btn {
    border-radius: 0;
}

.H_ctl:nth-child(6) {
    position: absolute;
    top: 17.6rem;
}

.H_ctl:nth-child(6) .H_btn {
    border-radius: 0;
    border-radius: 0 0 4px 4px;
}

.H_ctl:nth-child(7) {
    margin-top: 5rem;
}

.H_ctl:nth-child(8) {
    position: absolute;
    top: 8.15rem;
}

.H_ctl:nth-child(8) .H_btn {
    border-radius: 4px 4px 0 0;
}

.tooltip {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    top: 3.5px;
    left: 2.5rem;
    background: #26333b;
    padding: 4px 8px;
    color: #fff;
    border-radius: 4px;
    display: none;
    transition: all 0.2s ease;
    font-family: 'Inter';
    font-style: normal;
    width: max-content;
}

.H_btn:hover .tooltip {
    display: block;
}
