@import-normalize;

body,
p {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar-track {
    background-color: #f4f4f4;
}

::-webkit-scrollbar {
    width: 10px;
    background: #f4f4f4;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #d5d8da;
}

* > .tooltip-box {
    z-index: 999;
}
