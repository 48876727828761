.H_ib_body {
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 38px;
    left: -175px;
    right: 0;
    bottom: 1rem;
}

.H_ib_close svg {
    display: none;
}

.H_ib_close {
    background-image: url(../../../assets/svgs/imgPopupMarker/close-popup.svg);
    background-repeat: no-repeat;
    right: -145px;
    top: 40px;
    box-shadow: none;
}

.H_ib_tail {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 10px solid #ffffff;
    bottom: 4.8rem;
    left: -20px;
}

/* Class popup bubble tracker */

.H_ib_close_bubble_tracker {
    display: block;
}

.H_ib_content_bubble_tracker {
    display: inline;
}

/* Class popup subtitle */
.H_ib_body_subtitle {
    background-color: transparent;
    box-shadow: none;
    left: -49px;
    margin-bottom: 28px;
}

.H_ib_close_subtitle {
    display: none;
}
.H_ib_tail_subtitle {
    display: none;
}
.H_ib_content_subtitle {
    display: inline;
}

/* Class popup landmark */

.H_ib_close_landmark_register {
    display: none;
}
.H_ib_tail_landmark_register {
    left: -92px;
}

.H_ib_close_landmark_feature {
    display: none;
}

.H_ib_tail_landmark_feature {
    left: -27px;
    bottom: 4.7rem;
}

/* Class popup itinerary */

.H_ib_close_itinerary {
    display: none;
}
.H_ib_tail_itinerary {
    left: -85px;
}

/* Class popup Trail */

.H_ib_close_trail {
    display: none;
}
.H_ib_tail_trail {
    left: -92px;
}
